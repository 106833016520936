import { useEffect, useState } from "react"
import { useTour } from "@reactour/tour"
import { useGlobalState, useGallery } from "@app/contexts"
import clsx from "clsx"

import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { checkOnboardingActivation, isFirstVisit } from "@app/features/onboarding/utils"
import { useMobileMenu } from "features/gallery/store/hooks"
import { useDownloadGallery } from "@app/features/download/hooks"
import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { getProductDiscountData } from "@app/features/product-discount/utils"

import { MobileMenuActions, MobileMenuScenes } from "features/gallery/components/header/components"
import { OnboardingHint } from "@app/features/onboarding/components/onboarding-hint"
import { OnboardingPrintBlock } from "@app/features/onboarding/components/onboarding-print-block"
import { BurgerMenu } from "ui/icons"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Download } from "@app/ui/icons"

import { IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY, STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import {
  PRINT_ONBOARDING_PRINT_HINT,
  PRINT_ONDOARDING_IN_VIEW_MARGIN__MOBILE,
} from "@app/features/onboarding/constants"
import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./mobile-menu.module.scss"

const MobileMenu = ({ scenes, galleryId, isWithoutScenes, isDownloadEnabled, galleryName }) => {
  const {
    userAgent: { isMobile },
  } = useGlobalState()
  const { gallery } = useGallery()
  const { $isMobileMenuVisible, $toggleMobileMenuVisibility, $isMobileSubmenuVisible, $toggleMobileSubmenuVisibility } =
    useMobileMenu()

  const downloadGallery = useDownloadGallery(galleryId)

  function handleBurgerMenuClick() {
    $toggleMobileMenuVisibility()
    trackYandexMetrikaAction("click-menu")
  }

  function handleDownloadGalleryClick() {
    downloadGallery()
    trackYandexMetrikaAction("click-header-download-photo-mobile")
  }

  function lockScroll() {
    const $body = document.body

    $body.style.top = `-${window.scrollY}px`
    $body.setAttribute("data-scroll", `${window.scrollY}`)
    $body.classList.add("lock-scroll")
  }

  const [isVisibleTour, setIsVisibleTour] = useState(false)
  function handleScroll() {
    if (window.pageYOffset >= window.innerHeight) {
      setIsVisibleTour(true)
      window.removeEventListener("scroll", handleScroll)
    }
  }

  const { setIsOpen } = useTour()
  const isOnboardingEnabled = checkOnboardingActivation(gallery.settings)
  const isFirstVisitGallery = isFirstVisit(IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY)

  useEffect(() => {
    if (isFirstVisitGallery) {
      window.addEventListener("scroll", handleScroll)
    }
  }, [])

  const { $isOnboardingPrintVisible, $hideOnboardingPrint, $showOnboardingTour } = useOnboarding()

  useEffect(() => {
    if (isMobile && isOnboardingEnabled && isDownloadEnabled && isFirstVisitGallery && isVisibleTour) {
      setIsOpen(true)
      $showOnboardingTour()
      $hideOnboardingPrint()
      lockScroll()
    }
  }, [isVisibleTour])

  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled
  const printOnboardingShow =
    typeof window !== "undefined" ? window?.localStorage?.getItem(PRINT_ONBOARDING_PRINT_HINT) : false
  const discount = getProductDiscountData("PHOTOBOOK")
  const printOnboardingText = discount
    ? "В разделе Печать можно заказать\n фотокнигу. Макет уже готов."
    : "В разделе Печать можно заказать\n фотокнигу."
  const [inViewMargin, setInViewMargin] = useState(PRINT_ONDOARDING_IN_VIEW_MARGIN__MOBILE)

  function handleScrollOnboarding() {
    if (window.pageYOffset >= PRINT_ONDOARDING_IN_VIEW_MARGIN__MOBILE) {
      setInViewMargin(0)
    } else {
      setInViewMargin(PRINT_ONDOARDING_IN_VIEW_MARGIN__MOBILE)
    }
  }

  useEffect(() => {
    if (isPrintableProductsEnabled && printOnboardingShow !== "completed") {
      window.addEventListener("scroll", handleScrollOnboarding)
    }
  }, [])

  const cover = gallery?.cover?.sm
  return (
    <>
      <div className={styles["mobile-actions"]}>
        {isDownloadEnabled && (
          <button
            className={clsx(styles["download"], STEPS_CLASS_NAME_TOUR.downloadAll.name, QA_CLASS_NAMES.header.download)}
            onClick={() => handleDownloadGalleryClick()}
          >
            <IconWrapper
              light={<Download width="16" height="16" />}
              dark={<Download stroke="#fff" width="16" height="16" />}
            />
          </button>
        )}
        <OnboardingHint
          canVisible={$isOnboardingPrintVisible}
          visibleOnView={true}
          inViewMargin={inViewMargin}
          isMobileFixed={true}
          isTransparentClickEnabled={true}
          onTransparentClick={handleBurgerMenuClick}
          id={PRINT_ONBOARDING_PRINT_HINT}
          arrowPosition={{ desktop: "right-none", mobile: "none" }}
          offset="20px"
          space="0 -10px 0 0"
          type="circle"
          withImageType={true}
          isClickableContent={true}
          content={<OnboardingPrintBlock description={printOnboardingText} cover={cover}></OnboardingPrintBlock>}
        >
          <button
            className={clsx(styles["burger-menu"], QA_CLASS_NAMES.header.burger.open)}
            onClick={handleBurgerMenuClick}
          >
            <IconWrapper
              light={<BurgerMenu width="20" height="20" fill="#000" />}
              dark={<BurgerMenu width="20" height="20" fill="#fff" />}
            />
          </button>
        </OnboardingHint>
      </div>

      <div
        className={clsx(styles["mobile-menu-background"], {
          [styles["visible"]]: $isMobileMenuVisible || $isMobileSubmenuVisible,
        })}
      ></div>
      <MobileMenuActions
        visible={$isMobileMenuVisible}
        toggleMobileMenuVisibility={$toggleMobileMenuVisibility}
        toggleMobileSubmenuVisibility={$toggleMobileSubmenuVisibility}
        galleryId={galleryId}
        isWithoutScenes={isWithoutScenes}
        isDownloadEnabled={isDownloadEnabled}
        galleryName={galleryName}
      ></MobileMenuActions>
      <MobileMenuScenes
        visible={$isMobileSubmenuVisible}
        toggleMobileMenuVisibility={$toggleMobileMenuVisibility}
        toggleMobileSubmenuVisibility={$toggleMobileSubmenuVisibility}
        scenes={scenes}
      ></MobileMenuScenes>
    </>
  )
}

export { MobileMenu }
